import { PageFixModelHelper } from '../../services/model-helper/page-fix-model-helper';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';
(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormEditAltText', {
        templateUrl: 'app/modules/page-fix/forms/edit-alt-text/edit-alt-text.html',
        controller: AutofixEditAltTextController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    AutofixEditAltTextController.$inject = [
        'gettextCatalog',
        'LinksRepo',
        '$q',
        'ng2MonEventsService',
        'ng2MonModalService',
        'ng2SessionService',
        'PageFixFormEditAltText',
        'pageFixService',
        'PAGE_FIX_CHANGE_TYPES',
        'PAGE_FIX_FIXABLE_TYPES',
        'PageFixFormEditAltTextService',
    ];
    /* @ngInject */
    function AutofixEditAltTextController (
        gettextCatalog,
        LinksRepo,
        $q,
        ng2MonEventsService,
        ng2MonModalService,
        ng2SessionService,
        PageFixFormEditAltText,
        pageFixService,
        PAGE_FIX_CHANGE_TYPES,
        PAGE_FIX_FIXABLE_TYPES,
        PageFixFormEditAltTextService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getTranslationText = getTranslationText;
        vm.submit = submit;

        function activate () {
            vm.type = vm.resolve.type;
            vm.model = vm.resolve.model;
            vm.pageFix = vm.resolve.pageFix;
            vm.checkId = vm.pageFix ? vm.pageFix.change.check_id : vm.model.id;
            vm.fixableId = vm.resolve.typeId || vm.checkId;
            vm.imgThumbnail = null;
            vm.informationText = PageFixFormEditAltTextService.getTextDescription(vm.checkId);
            vm.altText = '';
            vm.loading = true;
            vm.urls = [];
            vm.pageFixSelector = '';
            if (!vm.pageFix) {
                getPageLinks()
                    .then(function (links) {
                        if (links.length === 0) {
                            sendError();
                            return;
                        }
                        return getOriginalUrls(links[0].link_id).then(
                            function (urls) {
                                vm.pageFixSelector = PageFixFormEditAltTextService.getPageFixSelector(urls);
                                vm.urls = urls.map(function (url) {
                                    return url.url;
                                });
                                vm.imgThumbnail = getImageURL();
                            },
                            function () {
                                vm.pageFixSelector = PageFixModelHelper.getSelectorValue(vm.model.source_code, ['src']);
                            },
                        );
                    }, angular.noop)
                    .finally(function () {
                        vm.loading = false;
                    });
            } else {
                vm.loading = false;
                vm.urls = PageFixFormEditAltTextService.getPageFixUrls(vm.pageFix);
                vm.imgThumbnail = getImageURL();
                vm.altText = vm.pageFix.change.value;
            }
        }

        function getTranslationText () {
            return gettextCatalog.getString(vm.templateInfo.translation, vm.templateInfo.translationScope);
        }

        function getImageURL () {
            if (vm.urls && vm.urls.length > 0) {
                if (/[a-zA-Z\/0-9]*:\/\//.test(vm.urls[0])) {
                    return vm.urls[0];
                } else {
                    return ng2SessionService.domain.url.replace(/\/$/, '') + '/' + vm.urls[0].replace(/^\//, '');
                }
            }
            return '';
        }

        // PROTECTED

        function getUrlOfSnippet () {
            PageFixFormEditAltText.setHTMLSnippet(vm.model.html_snippet);
            if (PageFixFormEditAltText.canReadSource()) {
                return PageFixFormEditAltText.getSource();
            }
            sendError();
            ng2MonModalService.alert(
                gettextCatalog.getString(
                    'Something went wrong. The Monsido Team has been notified of this error and will get back to you, as soon as possible.',
                ),
            );
            vm.formValid = false;
            return false;
        }

        function getPageLinks () {
            var params = {
                search: getUrlOfSnippet(),
                page: 1,
                page_size: 1,
            };
            if (params.search !== false) {
                return LinksRepo.getAllPageLinks(params);
            } else {
                return $q.resolve([]);
            }
        }

        function getOriginalUrls (id) {
            var link = {
                id: id,
            };
            return LinksRepo.getOriginalUrls(link);
        }

        function sendError () {
            ng2MonEventsService.run(
                'newException',
                'PageCorrect component \'pageFixFormEditAltText\' errored on snippet "' + vm.model.html_snippet + '"',
                'error',
            );
        }

        function submit () {
            var promise;
            var payload = {
                change: {
                    check_id: vm.checkId,
                    attribute: 'alt',
                    value: vm.altText,
                },
            };

            if (!vm.pageFix) {
                payload.selector = vm.pageFixSelector;
                payload.fixable_type = PAGE_FIX_FIXABLE_TYPES.accessibility_source_code;
                payload.fixable_id = vm.fixableId;
                payload.change_type = PAGE_FIX_CHANGE_TYPES.attribute + ':alt';
            }

            if (vm.pageFix) {
                promise = pageFixService.update(vm.pageFix, payload);
            } else {
                promise = pageFixService.create(payload, contextTokens.NO_GLOBAL);
            }

            promise.then(
                function (result) {
                    closeModal(result);
                },
                function (res) {
                    if (res.status === 422 && res.data.message === 'Record not unique') {
                        ng2MonModalService
                            .alert(gettextCatalog.getString('Someone have created this PageCorrect before you, on another check.'))
                            .then(angular.noop, angular.noop);
                    } else {
                        ng2MonModalService
                            .alert(gettextCatalog.getString('PageCorrect failed to save, the Monsido team has been notified of the error'))
                            .then(angular.noop, angular.noop);
                        ng2MonEventsService.run(
                            'newException',
                            'PageCorrect component \'pageFixFormEditAltText\' was unable to save"',
                            'error',
                            { payload: payload },
                        );
                    }
                },
            );
        }

        function closeModal (pageFix) {
            vm.close({ $value: pageFix });
        }
    }
})();
