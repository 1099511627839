import {
    CmsExtra,
    CrawlHistory,
    DomainGroupsEntity,
    DomainInterface,
    Features,
    LabelsEntity,
    LinkExcludesEntity,
    PageAssistSettings,
    PathConstraintsEntity,
    Scan,
    Settings,
} from '@monsido/modules/models/api/interfaces/domain.interface';
import { User } from './user';
import moment from 'moment';
import { domainModelUtils } from './domain-model-utils/domain-model.utils';
import { cloneDeep } from 'lodash';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';

// eslint-disable-next-line @typescript-eslint/no-shadow
export enum DomainType { Domain = 'Domain', DomainGroup = 'DomainGroup'}

export class Domain implements DomainInterface {
    active: boolean;
    id: number;
    customer_id: number;
    title: string;
    url: string;
    timezone: string;
    cms: string;
    crawled_pages: number;
    language: string;
    crawl_status?: {
        step: string,
        progress: number
    } | null;
    crawl_history?: CrawlHistory;
    features: Features;
    scan: Scan;
    path_constraints: PathConstraintsEntity[] = [];
    link_excludes: LinkExcludesEntity[] = [];
    domain_groups?: DomainGroupsEntity[];
    domain_users?: DomainUsersEntity[] = [];
    running_groups: boolean;
    cms_extra: CmsExtra;
    additional_internal_urls?: {
        operator: string;
        value: string;
    }[] = [];
    accessibility_source_code_excludes?: unknown[];
    source_code_excludes?: unknown[] = [];
    page_assist_settings?: PageAssistSettings;
    created_at: string;
    updated_at: string;
    favorite: boolean;
    monsido_script_installed?: boolean;
    labels?: LabelsEntity[];
    users?: User[];
    settings?: Settings;
    last_scan?: string;
    token?: string;

    constructor (domain: DomainInterface) {
        const offset = moment().utcOffset(); // Timezone in minutes

        domain = domain || {};

        this.active = !!domain.active;
        this.id = domain.id;
        this.customer_id = domain.customer_id;
        this.title = domain.title;
        this.url = domain.url;
        this.timezone = domain.timezone;
        this.cms = domain.cms;
        this.language = domain.language;
        this.crawl_status = domain.crawl_status;
        this.crawl_history = domain.crawl_history;
        this.crawled_pages = domain.crawled_pages ?? 0;

        if (domain.domain_users) {
            this.domain_users = cloneDeep(domain.domain_users);
        }

        if (domain.accessibility_source_code_excludes) {
            this.accessibility_source_code_excludes = [...domain.accessibility_source_code_excludes];
        }

        if (domain.token) {
            this.token = domain.token;
        }

        if (domain.features) {
            this.features = domain.features;
        } else {
            this.features = {
                statistics: false,
                accessibility: null,
                readability_test: null,
                readability_test_min_words: 0,
                cookie_banner: false,
                page_assist: false,
                page_fix: false,
                data_protection: false,
            };
        }

        if (domain.scan) {
            this.scan = domain.scan;
        } else {
            this.scan = {
                case_sensitive: true,
                connections_per_minute: 60,
                day: 'any',
                time: moment()
                    .set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
                    .toISOString(),
                use_lang_attribute: true,
            };
        }
        if (this.scan.login === null || typeof this.scan.login !== 'object') {
            this.scan.login = {
                type: 'none',
            };
        }
        this.path_constraints = domain.path_constraints ?? [];
        this.link_excludes = domain.link_excludes ?? [];
        this.domain_groups = domain.domain_groups;
        this.running_groups = domain.running_groups;
        this.cms_extra = domain.cms_extra;
        this.additional_internal_urls = domain.additional_internal_urls;
        if (domain.page_assist_settings) {
            this.page_assist_settings = domain.page_assist_settings;
        } else {
            this.page_assist_settings = {
                elements_to_hide: [],
                navigation: [],
                main: '',
                enabled: false,
                manual_startup: false,
                skip_to: null,
                theme: null,
                direction: null,
            };
        }
        this.created_at = domain.created_at;
        this.updated_at = domain.updated_at;
        this.favorite = domain.favorite;
        this.monsido_script_installed = domain.monsido_script_installed;
        this.labels = domain.labels;


        if (this.id > 0) {
            const origTime = this.scan.time;
            const utcTime = moment(this.scan.time, 'HH:mm:ss');
            const selectedTime = moment(this.scan.time, 'HH:mm:ss').add(offset, 'minutes');
            this.scan.time = selectedTime.toISOString(true);
            // Converting day from UTC to current timezone for displaying in the view: Shift one day forward if there is day difference
            this.scan.day = domainModelUtils.getCurrentScanDay(
                this,
                origTime,
                this.scan.day,
                domainModelUtils.calculateDayDiffBetweenTimes(selectedTime, utcTime) * -1,
            );

            this.updated_at = moment(this.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
            this.created_at = moment(this.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        }

        if (domain.last_scan) {
            this.last_scan = moment(domain.last_scan, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString();
        }

        this.settings = domainModelUtils.prepareSettings(domain);
    }

}
