import { Inject, Injectable } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';
import { Domain } from '@monsido/modules/models/api/domain';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { Customer } from '@monsido/modules/models/api/customer';
import { DomainGroup } from '@monsido/modules/models/api/domain-group';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@angular-shared-components';
import { OauthService } from '@monsido/oauth/oauth.service';
import { ChatsystemOptionInterface } from '@monsido/core/session/chatsystem-option.interface';
import { DOCUMENT } from '@angular/common';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

interface GetDomainID {
    (): number | undefined;

    /**
     * @deprecated use the method signature with no params
     */
    (params?: RequestParamsType): number | undefined;
}

interface GetGroupID {
    (): number | undefined;

    /**
     * @deprecated use the method signature with no params
     */
    (params?: RequestParamsType): number | undefined;
}

@Injectable()
export class SessionService {
    me?: User | null;
    roles: string[] = [];
    meObs: BehaviorSubject<User | null> = new BehaviorSubject(null);
    domainObs: BehaviorSubject<Domain | null> = new BehaviorSubject(null);
    domain: Domain | null = null;
    agreement: Agreement | null = null;
    agreements: BehaviorSubject<Agreement[] | null> = new BehaviorSubject(null);
    requestPath: string = '';
    isSudo: boolean = false;
    params: Record<string, string | number> = {};
    domainGroup: DomainGroup | null = null;
    chatSystem?: string;
    chatSystemOption?: ChatsystemOptionInterface;
    customer$: Observable<Customer | null>;

    _accessibilityMode = false;
    get accessibilityMode (): boolean {
        return this._accessibilityMode;
    }
    set accessibilityMode (value: boolean) {
        this._accessibilityMode = value;
        if (!this._accessibilityMode) {
            this.document.body.classList.remove('accessibility-mode');
        } else {
            this.document.body.classList.add('accessibility-mode');
        }
    }

    get customer (): Customer | null {
        return this.customerSubject.value;
    }

    set customer (value: Customer | null) {
        this.customerSubject.next(value);
    }

    private customerSubject: BehaviorSubject<Customer | null> = new BehaviorSubject(null);

    constructor (
        private localStorageService: LocalStorageService,
        private oauthService: OauthService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.customer$ = this.customerSubject.asObservable();
    }

    setChatSystem (chatSystem: string, options?: ChatsystemOptionInterface): void {
        this.chatSystem = chatSystem;
        this.chatSystemOption = options;
    }

    getChatSystemOptions () : ChatsystemOptionInterface | undefined {
        return this.chatSystemOption;
    }

    getChatSystem (): string | undefined {
        return this.chatSystem;
    }

    resetChatSystems (): void {
        this.chatSystem = undefined;
        this.chatSystemOption = undefined;
    }

    resetAgreements (): void {
        this.agreements = new BehaviorSubject<Agreement[]>([]);
    }

    getRequestPath (): string {
        return this.requestPath;
    }

    getRequestParams (): Record<string, string | number> {
        return this.params;
    }

    setRequestPath (path: string, params: Record<string, string | number>): boolean {
        this.requestPath = path;
        this.params = params;
        return true;
    }

    adaMode (): boolean {
        return Boolean(this.me && this.me.settings?.accessibility);
    }

    isDomainGroup (): boolean {
        return (this.domainGroup && this.domainGroup.id) != null;
    }

    isUserLoaded (): boolean {
        return !(this.me === undefined || this.me === null);
    }

    hasRole (role: string): boolean {
        return this.me !== undefined && this.me !== null && this.roles.indexOf(role) !== -1;
    }

    hasRoles (roles: string[]): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (!this.hasRole(roles[i])) {
                return false;
            }
        }
        return true;
    }

    getSelectedApi (): string {
        const selectedApi = this.localStorageService.getItem('selectedApi');
        if (selectedApi) {
            return selectedApi;
        } else {
            return this.oauthService.getApiPath();
        }
    }

    getSelectedServer (): string {
        const selectedApi = this.getSelectedApi() || '';
        const splitUrl = selectedApi.split('.');
        const name = splitUrl[1];
        if (name && isNaN(Number(name))) {
            return name.toUpperCase() + splitUrl[0].slice(-1);
        } else {
            return 'DEVELOPMENT';
        }
    }

    getGroupId: GetGroupID = (params?: RequestParamsType): number | undefined => {
        params = params || {};
        if (typeof params['group_id'] === 'number') {
            return params['group_id'];
        } else if (this.domainGroup) {
            return this.domainGroup.id;
        }
        return undefined;
    };

    getDomainId: GetDomainID = (params?: RequestParamsType): number | undefined => {
        params = typeof params === 'object' ? params : {};
        if (typeof params['domain_id'] === 'number') {
            return params['domain_id'];
        } else if (this.domain) {
            return this.domain.id;
        }
        return undefined;
    };

    getCustomerId (): number {
        if (this.customer) {
            return this.customer.id;
        }
        throw new Error('No customer id set');
    }

    isLiteCustomer (): boolean {
        return Boolean(this.customer && this.customer.plan_type === 'light');
    }

    clearSession (): void {
        this.me = null;
        this.meObs.next(null);
        this.customer = null;
        this.agreements.next([]);

        if (!this.meObs.isStopped) {
            this.meObs.unsubscribe();
        }
    }
}
